.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
}

.email-container {
  background: #2c2c2c;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.email-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-address {
  font-size: 1.2em;
  font-weight: bold;
}

.email-actions button {
  background: #444;
  color: #fff;
  border: none;
  padding: 10px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.email-actions button:hover {
  background: #666;
}

.messages-container {
  margin-top: 20px;
}

.message-item {
  background: #fff;
  color: #000;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: left;
}

button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

button:hover {
  background: #0056b3;
}
